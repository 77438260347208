:root{
    --primary-color:#6B737A ;
    --error-color: red;
    --primary-button-color: white;
    --orange: #FFA500;
    --light-gray: #C3BFBF;
    --medium-gray:#969696;
    --dark-gray: #302F2F;    
    --dark-white: #E3E3E3;
    --red: #E00000;
    --black: #000
}