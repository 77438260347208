.listView div.header{
    background-color: var(--black);
    color: var(--dark-white);
}

.listView div{
    background-color: var(--dark-gray);
    color:var(--primary-color);
    padding-top:1%;
    padding-bottom:1%;
}

.listView div div{
    cursor: pointer;
    
}

.listView div{
    border-bottom: 1px;
}

.listView{
    padding: 0;
    border-radius: 10px 10px;
}

div.selected{
    background-color: var(--dark-white);
    min-height: 10px;
}

div.checked{
    background-color: var(--orange);
}